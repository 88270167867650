.rdg {
	position: relative;
	z-index: 0;
	border: 1px solid #ddd;
	box-sizing: border-box;
	overflow-x: auto;
	overflow-y: scroll;
	-webkit-user-select: none;
	user-select: none;
	background-color: #fff;
	font-size: inherit !important;
}

.rdg *,
.rdg ::after,
.rdg ::before {
	box-sizing: inherit
}

.rdg-cell {
	display: inline-block;
	position: absolute;
	height: inherit;
	padding: 0 8px;
	border-right: 1px solid #ddd;
	border-bottom: 1px solid #ddd;
	background-color: inherit;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis
}

.rdg-cell-frozen {
	position: -webkit-sticky;
	position: sticky;
	z-index: 1
}

.rdg-cell-frozen-last {
	box-shadow: 2px 0 5px -2px rgba(136, 136, 136, .3)
}

.rdg-cell-mask {
	position: absolute;
	pointer-events: none;
	outline: 0
}

.rdg-checkbox-label {
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0
}

.rdg-checkbox-label-disabled {
	cursor: default
}

.rdg-checkbox-label-disabled .rdg-checkbox {
	border-color: #ccc;
	background: #ddd
}

.rdg-checkbox-input {
	all: unset;
	width: 0;
	margin: 0
}

.rdg-checkbox {
	content: '';
	width: 20px;
	height: 20px;
	border: 2px solid #ddd;
	background: #fff
}

.rdg-checkbox-input:checked+.rdg-checkbox {
	background: #005295;
	box-shadow: inset 0 0 0 4px #fff
}

.rdg-checkbox-input:focus+.rdg-checkbox {
	border-color: #62b8ff
}

.rdg-editor-container {
	position: absolute
}

.rdg-select-editor,
.rdg-text-editor {
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	box-sizing: border-box;
	width: calc(100% + 1px);
	height: calc(100% + 1px);
	padding: 1px 7px 0;
	margin: -1px 0 0 -1px;
	border: 2px solid #ccc;
	background-color: #fff;
	line-height: 1.2
}

.rdg-select-editor::placeholder,
.rdg-text-editor::placeholder {
	color: #999;
	opacity: 1
}

.rdg-select-editor:focus,
.rdg-text-editor:focus {
	border-color: #66afe9
}

.rdg-filter-row,
.rdg-header-row {
	width: var(--row-width);
	position: -webkit-sticky;
	position: sticky;
	background-color: #f9f9f9;
	font-weight: bold;
	-webkit-user-select: none;
	user-select: none;
	z-index: 3
}

.rdg-header-row,
.rdg-header-row * {
	font-size: 0.95rem;
}
.rdg-header-row {
	height: var(--header-row-height);
	line-height: var(--header-row-height);
	top: 0
}

.rdg-filter-row {
	height: var(--filter-row-height);
	line-height: var(--filter-row-height);
	top: var(--header-row-height)
}

.rdg-header-cell-resizer {
	cursor: col-resize;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	width: 10px
}

.rdg-cell .Select {
	max-height: 30px;
	font-weight: 400;
}

.rdg-header-sort-cell {
	cursor: pointer;
	display: flex
}

.rdg-header-sort-name {
	flex-grow: 1;
	overflow: hidden;
	text-overflow: ellipsis
}

.rdg-selected {
	border: 2px solid #66afe9
}

.rdg-selected .drag-handle {
	pointer-events: auto;
	position: absolute;
	bottom: -5px;
	right: -4px;
	background: #66afe9;
	width: 8px;
	height: 8px;
	border: 1px solid #fff;
	border-right: 0;
	border-bottom: 0;
	cursor: crosshair;
	cursor: -moz-grab;
	cursor: -webkit-grab;
	cursor: grab
}

.rdg-selected:hover .drag-handle {
	bottom: -8px;
	right: -7px;
	background: #fff;
	width: 16px;
	height: 16px;
	border: 1px solid #66afe9
}

.react-grid-cell-dragged-over-down,
.react-grid-cell-dragged-over-up {
	border: 1px dashed #000;
	background: rgba(0, 0, 255, .2)!important
}

.react-grid-cell-dragged-over-up {
	border-bottom-width: 0
}

.react-grid-cell-dragged-over-down {
	border-top-width: 0
}

.rdg-cell-copied {
	background: rgba(0, 0, 255, .2)!important
}

.rdg-row {
	width: var(--row-width);
	height: var(--row-height);
	line-height: var(--row-height);
	background-color: #fff
}

.rdg-row:hover {
	background-color: #f5f5f5
}

.rdg-row-selected {
	background-color: #dbecfa
}

.rdg-row-selected:hover {
	background-color: #c9e3f8
}

.rdg-summary-row {
	position: -webkit-sticky;
	position: sticky;
	z-index: 3
}

.rdg-summary-row>.rdg-cell {
	border-top: 2px solid #aaa
}